
					@import './src/sass/variables';
					@import './src/sass/mixins';
				


























































.embedded {
	display: flex;
	flex-flow: row nowrap;
	margin-bottom: $default_padding / 2;
}

.align-center .embedded {
	justify-content: center;
}

.align-right .embedded {
	justify-content: flex-end;
}
